import borders from './borders';
import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';
import sizes from './sizes';

const theme = {
  borders,
  colors,
  fontSizes: fonts,
  shadows,
  space: sizes,
  reactDatepicker: {
    daySize: [36, 40],
    colors: {
      accessibility: '#D80249',
      selectedDay: colors.primary[3],
      selectedDayHover: colors.primary[1],
      primaryColor: colors.primary[3],
    },
    datepickerZIndex: 1,
    // dateRangeGridTemplateColumns: '150px 30px 150px',
  },
};

export default theme;
