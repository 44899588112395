import React from "react";
import { Box } from "./index";

export default function FormLabel({ children, ...rest }) {
  return (
    <Box
      as="label"
      display="flex"
      __css={{
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#AEAFB3",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
