import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import styledSystem from '../utils/styledSystem';

const WrapperLink = styledSystem(styled(RouterLink)``);

const Link = forwardRef((props, ref) => {
  const { color = '#F87E0F', to = '#', children, ...rest } = props;

  return (
    <WrapperLink color={color} to={to} {...rest}>
      {children}
    </WrapperLink>
  );
});

export default Link;
