import React from 'react';
import styled from 'styled-components';
import { useStorage } from 'reactfire';

import {
  Box,
  Label,
  IconButton,
  Text,
  Flex,
  Uploader,
} from './index';
import { dispatch, useGlobalState } from '../states';
import deleteFromUrl from '../utils/deleteUploaded';

import { ReactComponent as Profile } from '../assets/svgs/profile.svg';
import { ReactComponent as Trash } from '../assets/svgs/trash.svg';

const LabelWrapper = styled(Label)``;

const Overlay = styled(Flex)`
  ${LabelWrapper}:hover & {
    bottom: 0px;
    transition: all 0.2s ease-in-out;
  }
`;

export default function AvatarImagePicker({
  accept = '.gif, .ico, .png, .jpg, .jpeg, .svg',
  src,
  withOverly,
  isSmall,
  defaultValue,
  control,
  name,
  disabled,
  randomable,
  pageId,
  ...rest
}) {
  const path = `images/${pageId}`;
  const storage = useStorage();
  const [pickedAvatar] = useGlobalState('pickedAvatar');
  const [currentAvatarIsPicked] = useGlobalState('currentAvatarIsPicked');
  const [currentUser] = useGlobalState('currentUser');
  const newRef = storage.ref(path);

  function handleTrash() {
    if (currentAvatarIsPicked) {
      deleteFromUrl({
        storage,
        downloadUrl: pickedAvatar.image,
      });
    }
    dispatch({
      pickedAvatar: {},
      type: 'pickedAvatar',
    });
  }

  function onUploadSuccess({ downloadUrl }) {
    dispatch({
      pickedAvatar: { image: downloadUrl },
      type: 'pickedAvatar',
    });
  }

  return (
    <Flex alignItems="center" flexDirection="column" {...rest}>
      <Uploader
        id="avatar-image-picker"
        storageRef={newRef}
        onUploadSuccess={onUploadSuccess}
        owner={currentUser.uid}
      >
        <LabelWrapper
          position="relative"
          display="inline-flex"
          border=".1rem dashed"
          borderColor="primary.2"
          borderRadius="50%"
          height={isSmall ? '60px' : '100px'}
          width={isSmall ? '60px' : '100px'}
          __css={{
            cursor: disabled ? 'default' : 'pointer',
          }}
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          as="button"
        >
          {pickedAvatar && pickedAvatar.image ? (
            <Box
              as="img"
              src={pickedAvatar.image}
              borderRadius="99999px"
              height={isSmall ? '40px' : '80px'}
              width={isSmall ? '40px' : '80px'}
              __css={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <Box
              borderRadius="99999px"
              width={isSmall ? '20px' : '50px'}
              height={isSmall ? '20px' : '50px'}
              __css={{
                objectFit: 'cover',
              }}
            >
              <Profile
                width={isSmall ? '20px' : '50px'}
                height={isSmall ? '20px' : '50px'}
              />
            </Box>
          )}
          {withOverly && (
            <Overlay
              bg="rgba(0,0,0,0.4)"
              position="absolute"
              bottom="-30%"
              width="100%"
              height="30%"
              box-sizing="border-box"
              transition="all 0.2s ease-in-out"
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text as="span" fontSize={['caption', 'body']} color="white">
                Change
              </Text>
            </Overlay>
          )}
        </LabelWrapper>
      </Uploader>
      {pickedAvatar && (
        <IconButton pt="xsmall" onClick={handleTrash}>
          <Trash />
        </IconButton>
      )}
    </Flex>
  );
}
