import styled from 'styled-components';
import styledSystem from '../utils/styledSystem';

const Text = styledSystem(styled.p``);

Text.defaultProps = {
  fontSize: 'paragraph',
};

export default Text;
