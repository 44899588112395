import React, { useCallback } from 'react';
import qs from 'query-string';

import { Box, Flex } from './index';
import { getRandomOptions } from '../utils/avatar';
import { dispatch, useGlobalState } from '../states';

const url = 'https://cool.bio/api';

export default function BigHeadsAvatar({ disabled, isSmall }) {
  const [randomAvatar] = useGlobalState('randomAvatar');
  const randomize = useCallback(() => {
    dispatch({
      randomAvatar: `${url}/svg?${qs.stringify(getRandomOptions())}`,
      type: 'randomAvatar',
    });
  }, []);
  return (
    <div>
      <Flex flexDirection="column" alignItems="center">
        <Flex
          border=".1rem dashed"
          borderColor="primary.2"
          borderRadius="50%"
          height={isSmall ? '60px' : '100px'}
          width={isSmall ? '60px' : '100px'}
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          __css={{
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          <Box
            as="img"
            src={randomAvatar}
            borderRadius="99999px"
            height={isSmall ? '40px' : '80px'}
            width={isSmall ? '40px' : '80px'}
            __css={{
              objectFit: 'cover',
            }}
          />
        </Flex>
        <Box
          as="button"
          bg="primary.3"
          p="xxsmall"
          fontSize={['tiny', 'caption']}
          lineHeight={1}
          borderRadius="20px"
          mt="xsmall"
          color="white"
          fontWeight="500"
          onClick={randomize}
        >
          Random
        </Box>
      </Flex>
    </div>
  );
}
