import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const containerEl = document.createElement('div');
let externalWindow = null;
const OauthPopup = props => {
  useEffect(() => {
    externalWindow = window.open(
      'http://localhost:5002/coolbio-f04cd/us-central1/spotifyRedirect',
      'Login',
      `width=600,height=400,left=200,top=200`
    );
    if (externalWindow) {
      externalWindow.document.body.appendChild(containerEl);
      externalWindow.addEventListener('beforeunload', () => {
        if (typeof closePopupWindowWithHooks === 'function') {
          props.closePopupWindowWithHooks();
        }
      });
    }

    return function cleanup() {
      if (externalWindow) {
        externalWindow.close();
        externalWindow = null;
      }
    };
  }, [props]);
  return createPortal(props.children, containerEl);
};

export default OauthPopup;
