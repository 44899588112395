import React, { memo, useCallback } from 'react';

import { dispatch } from '../states';

import { ReactComponent as Grab } from '../assets/svgs/drag-indicator.svg';
import { ReactComponent as Trash } from '../assets/svgs/trash.svg';

import {
  Flex,
  Icon,
  IconButton,
  Input,
  Box,
} from './index';

function SocialCard({ link, index, ...rest }) {
  const { icon, url, id } = link;

  const remove = useCallback(() => {
    dispatch({
      socialLinkRemoveAt: index,
      type: 'socialLinkRemoveAt',
    });
  }, [index]);

  const onUpdate = useCallback(
    (name, value) => {
      dispatch({
        socialLinkUpdateAt: {
          index,
          item: {
            [name]: value,
          },
        },
        type: 'socialLinkUpdateAt',
      });
    },
    [index]
  );

  return (
    <Flex
      borderRadius="10px"
      width="100%"
      px={'xsmall'}
      py={'small'}
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Box mr={'small'} as={Grab} />
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        mr="small"
        height="46px"
        width="46px"
      >
        <Icon
          icon={icon}
          color="primary.3"
          fontSize={['h5', 'h4', 'h3', 'h2']}
        />
      </Box>
      <Input
        borderRadius="36px"
        border="1px solid"
        borderColor="gray"
        fontSize={['caption', 'body', 'paragraph']}
        placeholder="https://social.link"
        width="250px"
        flex={1}
        p="xsmall"
        type="text"
        name={`${url}-${id}`}
        defaultValue={url}
        onChange={e => onUpdate('url', e.currentTarget.value)}
      />
      <IconButton onClick={remove} ml="small">
        <Trash />
      </IconButton>
    </Flex>
  );
}

export default memo(SocialCard);
