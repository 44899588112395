import React, { memo, useCallback } from 'react';
import { DateRangeInput } from '@datepicker-react/styled';
import { dispatch } from '../states';

function isDateBeforeToday(date) {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

function formateDate(date) {
  if (date) {
    if (new Date(date) !== 'Invalid Date' && !isNaN(new Date(date))) {
      return date;
    } else {
      return new Date(date.toDate());
    }
  } else {
    return null;
  }
}

function Calender({ index, startDate, endDate, focusedInput, id }) {
  const validStartDate = formateDate(startDate);
  const validEndDate = formateDate(endDate);

  const update = useCallback(
    data => {
      dispatch({
        linkUpdateAt: {
          index,
          item: data,
        },
        type: 'linkUpdateAt',
      });
    },
    [index]
  );

  return (
    <DateRangeInput
      onDatesChange={data => update(data)}
      onFocusChange={focusedInput => update({ focusedInput })}
      startDate={validStartDate}
      endDate={validEndDate}
      focusedInput={focusedInput}
      displayFormat="dd/MM/yy"
      vertical
      isDateBlocked={isDateBeforeToday}
      startDateInputId={`${id}-startDate`}
      endDateInputId={`${id}-endDate`}
    />
  );
}

export default memo(Calender);
