import React from 'react';
import { useWindowSize } from '../hooks';
import { IPhoneX } from './index';

function isZoomUseable() {
  return CSS.supports('zoom', 0.6);
}
function PhonePlayGround(props) {
  const { innerHeight, innerWidth } = useWindowSize();
  const isNotScalable =
    (innerWidth > 400 && innerWidth < 768) || innerHeight > 760 + 200;
  const scale = isNotScalable
    ? 1
    : Math.min(innerHeight / (640 + 600), innerWidth / 360);
  const zoomCss = isZoomUseable()
    ? { zoom: scale, left: ['auto', 'inherit', '60%'] }
    : {
        transform: [
          isNotScalable
            ? `scale(${scale})`
            : `translateX(-50%) scale(${scale})`,
          `scale(${scale})`,
          `translate(0, -50%) scale(${scale})`,
        ],
        top: `calc(50% + 80px)`,
        left: [isNotScalable ? 'auto' : '50%', 'inherit', '60%'],
      };
  return (
    <IPhoneX
      position={['relative', 'relative', 'fixed']}
      right={['inherit', 'inherit', '0']}
      __css={zoomCss}
      {...props}
    />
  );
}

export default PhonePlayGround;
