import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

const ConnectivityListener = () => {
  useConnectivityListener();
  return null;
};

export default ConnectivityListener;

export function useConnectivityListener() {
  const { addToast, removeToast } = useToasts();
  const [isOnline, setOnline] = useState(
    window ? window.navigator.onLine : false
  );
  const toastId = useRef(null);

  useEffect(() => {
    const onlineHandler = () => setOnline(true);
    const offlineHandler = () => setOnline(false);

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  function useUpdateEffect(isOnline) {
    const initialMount = useRef(true);

    useEffect(() => {
      function effect(params) {
        const content = (
          <Fragment>
            <strong>{isOnline ? 'Online' : 'Offline'}</strong>
            <div>
              {isOnline
                ? 'Editing is available again'
                : 'Changes you make may not be saved'}
            </div>
          </Fragment>
        );
        const callback = isOnline
          ? () => {
              removeToast(toastId.current);
              toastId.current = null;
            }
          : id => {
              toastId.current = id;
            };

        addToast(
          content,
          { appearance: 'info', autoDismiss: isOnline },
          callback
        );
      }
      if (initialMount.current) {
        initialMount.current = false;
      } else {
        effect();
      }
    }, [isOnline]);
  }

  useUpdateEffect(isOnline);
}
