const fonts = {
  tiny: '0.6rem',
  caption: '0.8rem',
  body: '0.9rem',
  paragraph: '1rem',
  subheader: '1.1rem',
  title: '1.2rem',
  h6: '1rem',
  h5: '1.3rem',
  h4: '1.5rem',
  h3: '1.8rem',
  h2: '2.5rem',
  h1: '3rem',
  h1x: '4.5rem',
};

export default fonts;
