import React from 'react';
import { Box, Flex } from './index';

function IPhoneX({ children, height = 640, width = 360, __css, ...props }) {
  const border = 60;
  const contentHeight = height;
  return (
    <Box
      // top={['inherit', 'inherit', '50%']}
      __css={{
        position: 'relative',
        width: `${width}px`,
        height: `${height}px`,
        margin: 'auto',
        border: '16px black solid',
        borderTopWidth: `${border}px`,
        borderBottomWidth: `${border}px`,
        borderRadius: '36px',
        boxSizing: 'content-box',
        '&:before': {
          content: '""',
          display: 'block',
          width: '60px',
          height: '5px',
          position: 'absolute',
          top: '-30px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#333',
          borderRadius: '10px',
        },
        '&:after': {
          content: '""',
          display: 'block',
          width: '35px',
          height: '35px',
          position: 'absolute',
          left: '50%',
          bottom: '-65px',
          transform: 'translate(-50%, -50%)',
          background: '#333',
          borderRadius: '50%',
        },
        ...__css,
      }}
      {...props}
    >
      <Flex
        width="100%"
        flexDirection="column"
        height={contentHeight}
        bg="white"
        overflowY="scroll"
      >
        {children}
      </Flex>
    </Box>
  );
}

export default IPhoneX;
