import * as React from 'react';
import { Flex, Box } from './index';

export default function Tooltip(props) {
  const { align = 'right', position = 'top', text, children } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const [yPos, setYPos] = React.useState(0);
  const [xPos, setXPos] = React.useState(0);
  const tooltipWrapperRef = React.useRef(null);
  const tooltipRef = React.useRef(null);
  function handleOnMouseEnter() {
    setIsVisible(true);
    setYPos(getYPos());
    setXPos(getXPos());
  }
  function getYPos() {
    if (!tooltipWrapperRef.current || !tooltipRef.current) return 0;
    if (position === 'top') {
      return (
        tooltipWrapperRef.current.getBoundingClientRect().top -
        tooltipRef.current.getBoundingClientRect().height
      );
    }
    return tooltipWrapperRef.current.getBoundingClientRect().bottom;
  }
  function getXPos() {
    if (!tooltipWrapperRef.current || !tooltipRef.current) return 0;
    if (align === 'left') {
      return (
        tooltipWrapperRef.current.getBoundingClientRect().right -
        tooltipRef.current.getBoundingClientRect().width
      );
    }
    return tooltipWrapperRef.current.getBoundingClientRect().left;
  }
  return (
    <>
      <Flex
        ref={tooltipWrapperRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </Flex>
      <Box
        ref={tooltipRef}
        __css={{
          position: 'fixed',
          top: `${yPos}px`,
          left: `${xPos}px`,
          backgroundColor: '#25122e',
          opacity: `${isVisible ? 1 : 0}`,
          color: '#e9cbd5',
          padding: '0.5rem',
          borderRadius: '0.4rem',
          textTransform: 'initial',
          fontSize: '90%',
          pointerEvents: 'none',
          transition: 'opacity 0.2s ease-in-out',
          zIndex: 100,
        }}
      >
        {text}
      </Box>
    </>
  );
}
