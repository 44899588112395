import React from 'react';
import Select from 'react-select';

function Dropdown({ options, defaultValue, value, onChange, size = 'large' }) {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      styles={{
        control: base => ({
          ...base,
          width: size === 'small' ? 150 : 280,
          height: size === 'small' ? 30 : 50,
          fontSize: size === 'small' ? 14 : 20,
          color: '#030047',
          borderColor: 'rgba(248,126,15,0.5)',
          '&:hover': {
            borderColor: 'rgba(248,126,15,1)',
          },
        }),
        singleValue: base => ({
          ...base,
          color: '#030047',
          fontSize: size === 'small' ? 14 : 20,
        }),
        menuList: base => ({
          ...base,
          fontSize: size === 'small' ? 14 : 20,
        }),
      }}
      theme={theme => ({
        ...theme,
        color: '#030047',
        colors: {
          ...theme.colors,
          primary: 'rgba(248,126,15,1)',
          primary25: 'rgba(248,126,15,0.25)',
          primary50: 'rgba(248,126,15,0.5)',
        },
      })}
    />
  );
}

export default Dropdown;
