import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useGlobalState } from '../states';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  DraggableSocialButtons,
} from '../components';

import { ReactComponent as Pencil } from '../assets/svgs/pencil.svg';

export default function SocialLinkButtons(props) {
  const [expanded, setExpanded] = useState(false);
  const [socialLinks = []] = useGlobalState('socialLinks');

  function onEdit() {
    setExpanded(!expanded);
  }
  return (
    <>
      <Box
        bg="white"
        minWidth="170px"
        position="relative"
        p="large"
        __css={{
          boxShadow: '0px 7px 15px -4px rgba(13, 15, 19, 0.1)',
        }}
        borderRadius="34px"
        my="large"
        {...props}
      >
        <Flex>
          {socialLinks.map(
            ({ icon, id }) =>
              icon && (
                <Icon
                  key={id}
                  icon={icon}
                  color="primary.3"
                  fontSize={['h5', 'h4', 'h3', 'h2']}
                  mr="small"
                />
              )
          )}
        </Flex>
        <IconButton
          onClick={onEdit}
          position="absolute"
          top="50%"
          right="-18px"
          __css={{
            transform: 'translateY(-50%)',
          }}
          height="36px"
          width="36px"
          borderRadius="9999px"
          bg="bridesmaid"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Pencil />
        </IconButton>
      </Box>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <DraggableSocialButtons />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
