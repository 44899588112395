import React from 'react';
import { Box, Flex, Text, Heading, Button } from './index';
import { ReactComponent as CircleGraph } from '../assets/svgs/circle-graph.svg';

export default function Graphcard({ label, value, link }) {
  return (
    <Flex
      bg="white"
      borderRadius="12px"
      p="medium"
      borderColor="primary.2"
      __css={{
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))',
      }}
      width="80%"
      mb={['small', 'medium', 'large']}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={[1, 1, '0 0 40%']}
      mr={[0, 0, 'medium']}
    >
      {!link && (
        <Box position="relative">
          <CircleGraph />
          <Text
            as="span"
            fontSize="subheader"
            fontWeight="500"
            color="gray"
            position="absolute"
            __css={{
              transform: 'translate(-50%, -50%)',
            }}
            top="45%"
            left="38%"
          >
            {value}
          </Text>
        </Box>
      )}
      <Heading as="h4" fontSize={['h6', 'h5', 'h4']} fontWeight="700">
        {label}
      </Heading>
      {link && (
        <>
          <Text as="span" fontSize="subheader" fontWeight="500" color="gray">
            {value}
          </Text>
          <Button variant="tertiary">{link}</Button>
        </>
      )}
    </Flex>
  );
}
