import React from "react";
import { FormLabel, Flex, Box } from "./index";

export default function FormInput({
  label,
  type: enumType,
  padding,
  margin,
  required,
  pattern,
  ...rest
}) {
  const { name } = rest;
  const type = enumType.toLowerCase();

  return (
    <Flex flexDirection="column" padding={padding} margin={margin}>
      {label && (
        <FormLabel htmlFor={name} marginBottom="10px">
          {label}
        </FormLabel>
      )}
      <Box
        as="input"
        id={name}
        name={name}
        type={type}
        __css={{
          background: "#FFFFFF",
          border: "1px solid",
          borderColor: "rgba(226, 226, 226, 0.5)",
          boxShadow: "0px 10px 15px rgba(222, 222, 222, 0.25)",
          borderRadius: "10px",
          height: "40px",
          paddingLeft: "10px",
          paddingRight: "10px",
          fontSize: "16px",
        }}
        {...rest}
      />
    </Flex>
  );
}
