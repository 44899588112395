import React, { useCallback, useState } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { spring } from 'popmotion';
import styled from 'styled-components';
import * as brands from '@fortawesome/free-brands-svg-icons';
import { FixedSizeList as List } from 'react-window';

import { ReactComponent as Grab } from '../assets/svgs/drag-indicator.svg';
import { ReactComponent as Trash } from '../assets/svgs/trash.svg';
import { ReactComponent as DownChevron } from '../assets/svgs/down-chevron.svg';

import { dispatch } from '../states';

import {
  Flex,
  Icon,
  IconButton,
  Box,
} from './index';

const OptionsContainer = styled(motion.ul)`
  display: flex;
  width: 60px;
  flex-direction: column;
  border-radius: 0.8125rem;
  justify-content: center;
  overflow-y: scroll;
  padding: 0;
  margin: 0.5rem 0 1.2rem 0;
  position: absolute;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 7px 15px -4px rgba(13, 15, 19, 0.1);
  border-radius: 36px;
`;

const OptionsElement = styled(motion.li)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WindowedRow = React.memo(({ onAdd, item }) => {
  return (
    item &&
    item !== 'fab' &&
    item !== 'prefix' && (
      <OptionsElement>
        <IconButton
          mb="small"
          __css={{
            '&:first-child': {
              marginTop: 'small',
            },
          }}
          onClick={() => onAdd(item)}
        >
          <Icon
            icon={item}
            color="primary.3"
            fontSize={['h5', 'h4', 'h3', 'h2']}
          />
        </IconButton>
      </OptionsElement>
    )
  );
});
const allBrandNames = Object.keys(brands);

export default function EmptySocialCard({ link, index, ...rest }) {
  const [expanded, setExpanded] = useState(false);
  const [brandNames, setBrandNames] = useState(allBrandNames);
  const { icon } = link;

  const animate = useAnimation();

  async function onOpen() {
    setExpanded(!expanded);
    if (!expanded) {
      await animate.start({ rotateX: 180, transition: { duration: 0.3 } });
    } else {
      await animate.start({ rotateX: 0, transition: { duration: 0.3 } });
    }
  }

  function onAdd(item) {
    update('icon', item);
  }

  function searchBrands(e) {
    const inputValue = e.currentTarget.value;
    const data = allBrandNames.filter(brand =>
      brand.toLowerCase().includes(inputValue.toLowerCase())
    );
    setBrandNames(data);
  }

  function onUrlChange(e) {
    const { value } = e.currentTarget;
    update('url', value);
  }

  const update = useCallback(
    (name, value) => {
      dispatch({
        socialLinkUpdateAt: {
          index,
          item: {
            [name]: value,
          },
        },
        type: 'socialLinkUpdateAt',
      });
    },
    [index]
  );

  const remove = useCallback(() => {
    dispatch({
      socialLinkRemoveAt: index,
      type: 'socialLinkRemoveAt',
    });
  }, [index]);

  return (
    <Flex
      borderRadius="10px"
      width="100%"
      px={'xsmall'}
      py={'small'}
      alignItems="center"
      {...rest}
    >
      <Box mr={'small'} as={Grab} />
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        mr="small"
        height="46px"
        width="46px"
      >
        {icon ? (
          <Icon
            icon="faTwitter"
            color="primary.3"
            fontSize={['h5', 'h4', 'h3', 'h2']}
          />
        ) : (
          <Box position="relative">
            <motion.div
              className="block"
              animate={{
                rotate: expanded ? 180 : 0,
              }}
            >
              <IconButton
                bg="bridesmaid"
                borderRadius="9999px"
                height="46px"
                width="46px"
                justifyContent="center"
                alignItems="center"
                display="flex"
                onClick={onOpen}
              >
                <Box
                  as={DownChevron}
                  __css={{
                    fill: 'primary.3',
                  }}
                  width="15px"
                  height="15px"
                />
              </IconButton>
            </motion.div>
            <AnimatePresence initial={false}>
              <OptionsContainer
                key="content"
                exit="collapsed"
                initial={{ height: 0 }}
                animate={
                  expanded
                    ? {
                        height: '300px',
                        boxShadow: '0px 7px 15px -4px rgba(13, 15, 19, 0.1)',
                      }
                    : {
                        height: 0,
                        boxShadow: '0px 7px 15px -4px rgba(13, 15, 19, 0.05)',
                        transition: { type: spring },
                      }
                }
              >
                <List
                  height={300}
                  width={60}
                  itemCount={brandNames.length}
                  itemSize={10}
                  itemData={brandNames}
                >
                  {({ data, index, style }) => {
                    const item = data[index];
                    return (
                      <WindowedRow
                        key={item}
                        item={item}
                        onAdd={onAdd}
                        style={style}
                      />
                    );
                  }}
                </List>
              </OptionsContainer>
            </AnimatePresence>
          </Box>
        )}
      </Box>
      <motion.div animate={animate}>
        {expanded ? (
          <Box
            as="input"
            borderRadius="36px"
            border="1px solid"
            borderColor="gray"
            fontSize={['caption', 'body', 'paragraph']}
            placeholder={'Search brands'}
            onChange={searchBrands}
            width="250px"
            flex={1}
            name="searchBrands"
            key="searchBrands"
            p="xsmall"
            __css={{
              transform: 'rotateX(180deg)',
            }}
            defaultValue=""
          />
        ) : (
          <Box
            as="input"
            borderRadius="36px"
            border="1px solid"
            borderColor="gray"
            fontSize={['caption', 'body', 'paragraph']}
            placeholder={'https://social.link'}
            onChange={onUrlChange}
            width="250px"
            flex={1}
            name="newBrandUrl"
            p="xsmall"
            __css={{
              transform: 'rotateX(0)',
            }}
          />
        )}
      </motion.div>
      <AnimatePresence initial={false}>
        {!expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <IconButton onClick={remove} ml="small">
              <Trash />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
}
