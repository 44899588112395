import React, { memo } from 'react';
import styled from 'styled-components';
import { Label, Uploader } from './index';

const LabelWrapper = styled(Label)``;


function ImagePicker({
  accept = '.gif, .ico, .png, .jpg, .jpeg, .svg',
  name,
  disabled,
  children,
  onChange,
  id,
  onUploadSuccess,
  storageRef,
  owner,
  ...rest
}) {

  return (
    <Uploader
      id={id}
      storageRef={storageRef}
      onUploadSuccess={onUploadSuccess}
      owner={owner}
    >
      <LabelWrapper
        position="relative"
        display="inline-flex"
        __css={{
          cursor: disabled ? 'default' : 'pointer',
        }}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        {...rest}
      >
        {children}
      </LabelWrapper>
    </Uploader>
  );
}

export default memo(ImagePicker);
