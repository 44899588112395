import styled from 'styled-components';
import css from '@styled-system/css';

import styledSystem from '../utils/styledSystem';

import { Tooltip } from './index';

const IconButtonWrapper = styledSystem(styled.button`
  min-width: 0;
  background: transparent;
  ${css({
    '&:hover svg': {
      color: 'primary.4',
    },
  })}
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')}
`);

function IconButton(props) {
  const { tooltipPosition, tooltipAlign, tooltipText, ...rest } = props;
  return tooltipText ? (
    <Tooltip position={tooltipPosition} align={tooltipAlign} text={tooltipText}>
      <IconButtonWrapper {...rest} />
    </Tooltip>
  ) : (
    <IconButtonWrapper {...rest} />
  );
}

export default IconButton;
