import React, { memo, useCallback, useState } from 'react';
import { useStorage } from 'reactfire';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Flex,
  IconButton,
  Switch,
  Input,
  Box,
  Calendar,
  ImagePicker,
  InputFeild,
} from './index';
import { ReactComponent as Grab } from '../assets/svgs/drag-indicator.svg';
import { ReactComponent as Arrow } from '../assets/svgs/rightArrow.svg';
import { ReactComponent as Profile } from '../assets/svgs/profile.svg';
import { ReactComponent as CalendarIcon } from '../assets/svgs/calendar.svg';
import { ReactComponent as Trash } from '../assets/svgs/trash.svg';

import { dispatch, useGlobalState } from '../states';

const Card = props => {
  const { link = {}, index, forwardLink, ...rest } = props;
  const [isOpen, setOpen] = useState(link.startDate);
  const storage = useStorage();
  const [pageId] = useGlobalState('pageId');
  const [currentUser] = useGlobalState('currentUser');
  const path = `images/${pageId}`;
  const newRef = storage.ref(path);

  const remove = useCallback(() => {
    dispatch({
      linkRemoveAt: index,
      type: 'linkRemoveAt',
    });
  }, [index]);

  const updateForwardLink = useCallback(() => {
    dispatch({
      forwardLink: {
        id: forwardLink.id === link.id ? '' : link.id,
        url: forwardLink.id === link.id ? '' : link.url,
      },
      type: 'forwardLink',
    });
  }, [forwardLink.id, link.id, link.url]);

  const update = useCallback(
    (name, value) => {
      dispatch({
        linkUpdateAt: {
          index,
          item: {
            [name]: value,
          },
        },
        type: 'linkUpdateAt',
      });
    },
    [index]
  );

  const onUploadSuccess = useCallback(
    ({ downloadUrl }) => {
      dispatch({
        linkUpdateAt: {
          index,
          item: {
            image: downloadUrl,
          },
        },
        type: 'linkUpdateAt',
      });
    },
    [index]
  );

  const updateCalenderInitial = useCallback(
    data => {
      dispatch({
        linkUpdateAt: {
          index,
          item: {
            startDate: null,
            endDate: null,
            focusedInput: null,
          },
        },
        type: 'linkUpdateAt',
      });
    },
    [index]
  );

  const onCalenderClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Flex
      bg="white"
      boxShadow="0px 7px 15px -4px rgba(13, 15, 19, 0.1)"
      borderRadius="10px"
      width="100%"
      px={'xsmall'}
      py={'small'}
      justifyContent="space-between"
      flexDirection="column"
      {...rest}
    >
      <Flex height="100%" alignItems="center">
        <Box mr={'small'} as={Grab}></Box>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="space-around"
          width="12.5em"
          flex={1}
        >
          <Flex pb="xxsmall" pr="small">
            <Input
              width="100%"
              placeholder="Label here"
              fontWeight="600"
              fontSize={['body', 'paragraph', 'subheader']}
              color="text"
              name={`links[${index}].title`}
              defaultValue={link.title}
              onChange={e => update('title', e.currentTarget.value)}
            />
          </Flex>
          <Box pb="xxsmall" pr="small">
            <InputFeild
              width="100%"
              placeholder="https://cool.bio"
              fontWeight="500"
              fontSize={['caption', 'body', 'paragraph']}
              name={`links[${index}].url`}
              color="#030047"
              defaultValue={link.url}
              onChange={e => update('url', e.currentTarget.value)}
              type="url"
              errorText="Url must start with http or https."
            />
          </Box>
          <Flex width="5em" justifyContent="space-between">
            <IconButton
              onClick={updateForwardLink}
              tooltipText={
                forwardLink.id === link.id
                  ? 'click to remove'
                  : 'forward all to this link'
              }
            >
              <Box as={Arrow} opacity={forwardLink.id === link.id ? 1 : 0.7} />
            </IconButton>
            {link.image ? (
              <IconButton
                onClick={() => update('image', '')}
                tooltipText="click to remove image"
              >
                <Box as={Profile} opacity={1} />
              </IconButton>
            ) : (
              <ImagePicker
                id={link.id}
                storageRef={newRef}
                onUploadSuccess={onUploadSuccess}
                owner={currentUser.owner}
                tooltipText="click to add image"
              >
                <Box as={Profile} opacity={0.7} />
              </ImagePicker>
            )}
            {link.startDate ? (
              <IconButton
                onClick={updateCalenderInitial}
                tooltipText="click to remove"
              >
                <Box as={CalendarIcon} opacity={1} />
              </IconButton>
            ) : (
              <IconButton
                onClick={onCalenderClick}
                tooltipText={
                  isOpen ? 'hide calender' : 'click to add date range'
                }
              >
                <Box as={CalendarIcon} opacity={0.7} />
              </IconButton>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignlinks="center">
          <Switch
            name={`links[${index}].show`}
            onChange={e => update('show', e.target.checked)}
            defaultChecked={link.show}
          />
          <IconButton onClick={remove} mt="small">
            <Trash />
          </IconButton>
        </Flex>
      </Flex>
      <Box mt="xsmall">
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <Calendar index={index} {...link} />
            </motion.section>
          )}
        </AnimatePresence>
      </Box>
    </Flex>
  );
};

export default memo(Card);
