import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useToasts } from 'react-toast-notifications';
import { useStorage, useFirestore } from 'reactfire';
import { useHistory } from 'react-router-dom';

import {
  Flex,
  IconButton,
  Link,
  Button,
  Text,
  Switch,
  Box,
  Tooltip,
  Modal,
  Heading,
} from './index';

import { dispatch, useGlobalState } from '../states';

import { ReactComponent as Copy } from '../assets/svgs/copy.svg';
import { ReactComponent as Phone } from '../assets/svgs/phone.svg';
import { ReactComponent as Desktop } from '../assets/svgs/desktopicon.svg';

const ProfileNav = props => {
  const { pageId } = props;
  const firestore = useFirestore;
  const storage = useStorage();
  const history = useHistory();
  const ref = firestore().collection('pages');
  const [isOpen, setIsOpen] = useState(false);
  // const [affiliateEnabled] = useGlobalState('affiliateEnabled');
  const [appointmentsEnabled] = useGlobalState('appointmentsEnabled');
  const { addToast } = useToasts();
  const [, copyToClipboard] = useCopyToClipboard({
    noUserInteraction: false,
  });

  const currentLink = `https://cool.bio/${props.userName}`;

  function copyLink() {
    copyToClipboard(currentLink);
    addToast('Link copied!', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function askConfirm() {
    setIsOpen(true);
  }

  function confirmPublish() {
    dispatch({
      ref: ref.doc(pageId),
      pageId,
      storage,
      addToast,
      type: 'submitPublishData',
      history
    });
    setIsOpen(false);
  }

  // uncomment when affilates are back
  /* function enableAffiliate() {
    dispatch({
      type: 'affiliateEnabled',
    });
  } */

  function enableAppointments() {
    dispatch({
      type: 'appointmentsEnabled',
    });
  }

  return (
    <Flex
      color="grey"
      justifyContent="space-between"
      flexDirection="column"
      width="100%"
      p={['0', 'small']}
      py={'small'}
      borderBottom="1px dashed rgba(248,126,15,1)"
    >
      <Flex flexDirection={['column', 'row']} alignItems={['left', 'center']}>
        <Text>Your link is</Text>
        <Flex alignItems="center" data-tut="coolbio__profileUrl">
          <Link
            as="a"
            href={currentLink}
            color="blue"
            target="_blank"
            pl={['0', 'xxsmall']}
            pr="xsmall"
          >
            {currentLink}
          </Link>
          <IconButton onClick={copyLink}>
            <Copy />
          </IconButton>
        </Flex>
      </Flex>
      <Flex
        flexDirection={['column', 'row']}
        alignItems={['left', 'center']}
        justifyContent="space-between"
      >
        <Flex flexDirection={['column', 'row']} alignItems={['left', 'center']}>
          <Flex pr="xsmall" mt={['small', 'small', 0]}>
            <Text mr={['xxsmall', 'xsmall']}>Advance editing</Text>
            <Tooltip text="coming soon">
              <Switch disabled />
            </Tooltip>
          </Flex>
          <Flex alignItems="center" pr="xsmall" mt={['small', 'small', 0]}>
            <Text mr={['xxsmall', 'xsmall']}>Affiliates</Text>
            <Tooltip text="coming soon">
              <Switch
                // onChange={enableAffiliate}
                // defaultChecked={affiliateEnabled}
                disabled
              />
            </Tooltip>
          </Flex>
          <Flex alignItems="center" mt={['small', 'small', 0]} data-tut="coolbio__appointments">
            <Text mr={['xxsmall', 'xsmall']}>Appointments</Text>
            <Tooltip text="enable to earn">
              <Switch
                onChange={enableAppointments}
                defaultChecked={appointmentsEnabled}
              />
            </Tooltip>
          </Flex>
        </Flex>
        <Flex m="small">
          <Flex
            borderRadius="9999px"
            bg="primary.1"
            mr="small"
            width="50px"
            height="50px"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton>
              <Box
                as={Phone}
                width="30px"
                height="30px"
                __css={{ fill: 'primary.3' }}
              />
            </IconButton>
          </Flex>
          <IconButton
            tooltipPosition="top"
            tooltipAlign="right"
            tooltipText="Coming soon"
          >
            <Box
              as={Desktop}
              width="30px"
              height="30px"
              __css={{ fill: 'primary.2' }}
            />
          </IconButton>
        </Flex>
        <Button onClick={askConfirm} data-tut="coolbio__Publishbutton">Publish</Button>
      </Flex>
      <Modal
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <Box width={['250px', '400px', '400px']}>
          <Heading mb="small" fontSize={['h5', 'h4', 'h3']}>
            Are you sure?
          </Heading>
          <Text fontSize={['tiny', 'caption', 'body']}>
            You can always go back and change the published links.
          </Text>
          <Flex justifyContent="center" mt="large">
            <Button onClick={confirmPublish}>Confirm</Button>
          </Flex>
        </Box>
      </Modal>
    </Flex>
  );
};

export default ProfileNav;
