const config = {
  apiKey: 'AIzaSyD86wUulnJimM9ro-IDncgsBNXQku01y6g',
  authDomain: 'auth.cool.bio',
  projectId: 'coolbio-f04cd',
  storageBucket: 'coolbio-f04cd.appspot.com',
  messagingSenderId: '998802421464',
  appId: '1:998802421464:web:a3d9aab8e3bbeae6e7c9ad',
  measurementId: 'G-DW94LP2NTZ',
};

export default config;
