import React from 'react';

import { Box, Flex, Text, Heading } from './index';
import { ReactComponent as RightArrow } from '../assets/svgs/right-arrow.svg';

export default function ProfileCard({ avatar, date, name, userName }) {
  return (
    <Box
      bg="white"
      borderRadius="12px"
      p="medium"
      __css={{
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))',
      }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex
          borderRadius="10px"
          width="35px"
          height="35px"
          bg="primary.1"
          alignItems="center"
          justifyContent="center"
        >
          <Text as="span" fontSize="title" fontWeight="500">
            {avatar}
          </Text>
        </Flex>
        <Text as="span" fontSize="caption" fontWeight="500" color="gray">
          {date}
        </Text>
      </Flex>
      <Flex pt="medium" alignItems="center" justifyContent="space-between">
        <Heading as="h4" fontSize={['h6', 'h5', 'h4']} fontWeight="700">
          {name || 'Please add'}
        </Heading>
        <Box
          mr="medium"
          p="small"
          __css={{
            '&:hover path': {
              color: 'primary.4',
            },
          }}
        >
          <Box
            as={RightArrow}
            color="primary.3"
            __css={{
              'path:first-child': {
                stroke: 'primary.3',
              },
            }}
          />
        </Box>
      </Flex>
      <Heading
        as="h5"
        fontSize="h5"
        fontWeight="500"
        color="gray"
        textAlign="left"
      >
        {userName}
      </Heading>
    </Box>
  );
}
