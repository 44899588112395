import React from 'react';
import { Box, Loader } from './index';

import { ReactComponent as Cancel } from '../assets/svgs/cancel.svg';
import { ReactComponent as Checkbox } from '../assets/svgs/checkbox.svg';

export default function MaskedInput(props) {
  const {
    className,
    fontSize = '1rem',
    fontWeight = 'normal',
    prefix,
    height = '60px',
    children,
    maxWidth = '350px',
    width,
    borderRadius = 10,
    suffix,
    error,
    success,
    loading,
    onChange,
    placeholder,
    defaultValue,
    ...rest
  } = props;

  const inputWrapperCss = {
    border: '2px solid #F1F1F1',
    boxShadow: '0px 10px 15px rgba(222, 222, 222, 0.25)',
    borderRadius,
    maxWidth,
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: '#fff',
    __css: {
      '&:focus-within': {
        '@media (-webkit-min-device-pixel-ratio: 0)': {
          outlineColor: '-webkit-focus-ring-color',
          outlineStyle: 'auto',
        },
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineColor: 'Highlight',
      },
    },
  };

  return (
    <Box {...inputWrapperCss}>
      {prefix && prefix}
      <Box
        as="input"
        className={className}
        fontSize={fontSize}
        fontWeight={fontWeight}
        height="100%"
        width="100%"
        placeholder={placeholder}
        defaultValue={defaultValue}
        __css={{
          border: 'none',
          outline: 'none',
        }}
        onChange={onChange}
        {...rest}
      >
        {children}
      </Box>
      {error && <Box as={Cancel} mr="small" width="20px" height="20px" />}
      {success && <Box as={Checkbox} mr="small" width="20px" height="20px" />}
      {loading && (
        <Loader isLoading width="24px" mr="small" size={14} strokeWidth={2} />
      )}
    </Box>
  );
}
