import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import css from '@styled-system/css';

import { Flex } from './index';

const RouterLink = styled(NavLink)(
  css({
    padding: ['small', 'medium'],
    color: 'blue',
    borderBottom: '5px solid',
    borderColor: 'transparent',
    transition: '0.3s',

    '&.active': {
      background: 'transparent',
      borderBottom: '5px solid',
      borderColor: 'primary.3',
      color: 'primary.3',
    },
  })
);

export default function Tab({ children, to = '#' }) {
  return (
    <Flex
      as="li"
      display="flex"
      justifyContent="center"
      alignItems="center"
      listStyle="none"
      background="none"
    >
      <RouterLink to={to} exact>{children}</RouterLink>
    </Flex>
  );
}
