const colors = {
  white: '#fff',
  black: '#000',
  alabaster: '#F9F9F9',
  transparent: 'transparent',
  light: '',
  disabled: '',
  primary: [
    'rgba(248,126,15,0.05)',
    'rgba(248,126,15,0.1)',
    'rgba(248,126,15,0.5)',
    'rgba(248,126,15,1)',
    '#c6650c',
  ],
  border: 'rgba(248,126,15,0.5)',
  text: '#33272A',
  gray: 'rgba(174,175,179,1)',
  success: '#00d68f',
  info: ['rgba(0,149,255, 0.5)', 'rgba(0,149,255, 1)'],
  warning: '#ffaa00',
  danger: '#ff3d71',
  blue: '#030047',
  bridesmaid: '#FEF2E7',
};

export default colors;
