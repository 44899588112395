import React, { useEffect, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import ImageEditor from '@uppy/image-editor';
import { DashboardModal } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/image-editor/dist/style.css';


import FirebaseCloudStorage from '../utils/firebaseCloudStorage';

const Uploader = ({ id, children, storageRef, onUploadSuccess, owner }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const uppy = useMemo(() => {
    return new Uppy({
      id,
      allowMulipleUploads: false,
      logger: Uppy.debugLogger,
      restrictions: {
        maxFileSize: 3 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          '.jpg',
          '.jpeg',
          '.png',
          '.gif',
          '.JPG',
          '.JPEG',
          '.PNG',
          '.GIF'
        ],
      },
    })
      .use(ImageEditor, {
        id: 'ImageEditor',
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
        },
      })
      .use(Webcam, {
        id: 'Webcam',
        modes: ['picture'],
      })
      .use(FirebaseCloudStorage, { storageRef, owner })
      .on('upload-success', (file, snapshot, downloadUrl) => {
        if(typeof onUploadSuccess === 'function') {
          onUploadSuccess({
            file,
            snapshot,
            downloadUrl
          });
        }
        handleClose();
      })
  }, [id, onUploadSuccess, storageRef, owner]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleOpen, })}
      <DashboardModal
        target={false}
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        closeModalOnClickOutside
        open={isModalOpen}
        onRequestClose={handleClose}
        plugins={['Webcam', 'ImageEditor']}
        metaFields={[
          { id: 'name', name: 'Name', placeholder: 'File name' },
        ]}
      />
    </>
  );
};

Uploader.propTypes = {
  id: PropTypes.string,
};

export default memo(Uploader);