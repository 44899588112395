import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const WrapperRouterLink = styled(RouterLink)`
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
  padding: 15px;
  transition: 0.3s;

  svg {
    width: 30px;
    height: 30px;
    opacity: 0.4;
  }

  &.active {
    background: rgba(248, 126, 15, 0.1);
    border-radius: 9999px;

    svg {
      opacity: 1;
    }
  }
`;

const NavLink = forwardRef((props, ref) => {
  const { color = '#F87E0F', to = '#', children, ...rest } = props;

  return (
    <WrapperRouterLink color={color} to={to} {...rest}>
      {children}
    </WrapperRouterLink>
  );
});

export default NavLink;
