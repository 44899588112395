export default async function deleteFromUrl({ storage, downloadUrl }) {
  const url = new URL(downloadUrl);
  const storageUrl = `${url.origin}${url.pathname}`
  const httpsRef = storage.refFromURL(storageUrl);
  return await httpsRef
    .delete()
    .then(() => {
      console.log('success');
    })
    .catch((e) => {
      console.log('error', e);
    });
}
