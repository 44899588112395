import React from 'react';
import styled from 'styled-components';
import * as brands from '@fortawesome/free-brands-svg-icons';
import * as solids from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  compose,
  space,
  color,
  layout,
  typography,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow,
  width,
  minWidth,
  height,
  minHeight,
  fontSize,
} from 'styled-system';

import { base } from '../utils/styledSystem';

const mergeIcons = {
  ...solids,
  ...brands,
};

const WrapperIcon = styled(FontAwesomeIcon)`
  ${compose(
    space,
    color,
    layout,
    typography,
    flexbox,
    border,
    background,
    position,
    grid,
    shadow,
    width,
    minWidth,
    height,
    minHeight,
    fontSize
  )}
  ${base}
`;

export const Icon = ({ icon, ...rest }) => {
  return <WrapperIcon icon={mergeIcons[icon]} {...rest} />;
};

export default Icon;
