import { createStore } from 'react-hooks-global-state';
import qs from 'query-string';
import dayjs from 'dayjs';
import { getRandomOptions } from '../utils/avatar';
import guidGenerator from '../utils/guidGenerator';
import {
  questionPush,
  questionMove,
  questionUpdateAt,
  submitQuestionData,
  managePageload,
  questionRemoveAt,
} from '../actions/manageQuestions';

const url = 'https://cool.bio/api';

export const initialState = {
  currentUser: {},
  loginType: '',
  pageId: '',
  affiliateEnabled: true,
  appointmentsEnabled: true,
  currentEdit: {
    image: '',
    displayName: 'name',
    userName: 'username',
  },
  defaultDisplayName: '',
  defaultUserName: '',
  forwardLink: {
    id: '',
    url: '',
  },
  randomAvatar: `${url}/svg?${qs.stringify(getRandomOptions())}`,
  pickedAvatar: {
    image: '',
  },
  currentAvatarIsPicked: true,
  links: [],
  questions: [
    {
      title: 'Your name',
      show: true,
      type: 'text',
      id: 'seeker',
    },
    {
      title: 'Your email',
      show: true,
      type: 'email',
      id: 'seekerEmail',
      required: true,
    },
  ],
  socialLinks: [
    {
      icon: 'faInstagram',
      url: '',
      id: guidGenerator(),
    },
    {
      icon: 'faTwitter',
      url: '',
      id: guidGenerator(),
    },
    {
      icon: 'faFacebook',
      url: '',
      id: guidGenerator(),
    },
  ],
  pagesList: [],
  dashboardAnlytics: {
    totalViews: 0,
    pageViews: [
      {
        views: 0,
        date: dayjs().format('DD-MM-YYYY'),
      },
    ],
    loading: true,
  },
};

export const { dispatch, useGlobalState } = createStore(
  (state, action) => {
    const arr = [...state.links];
    const socialLinkArr = [...state.socialLinks];
    switch (action.type) {
      case 'loginType':
        return {
          ...state,
          loginType: action.loginType,
        };
      case 'updateUser':
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            ...action.user,
          },
        };
      case 'forwardLink':
        return {
          ...state,
          forwardLink: action.forwardLink,
        };
      case 'affiliateEnabled':
        return {
          ...state,
          affiliateEnabled: !state.affiliateEnabled,
        };
      case 'appointmentsEnabled':
        return {
          ...state,
          appointmentsEnabled: !state.appointmentsEnabled,
        };
      case 'dashboardAnlytics':
        return {
          ...state,
          dashboardAnlytics: {
            ...state.dashboardAnlytics,
            ...action.dashboardAnlytics,
            pageViews: action?.dashboardAnlytics?.pageViews?.length
              ? action.dashboardAnlytics.pageViews
              : initialState.dashboardAnlytics.pageViews,
          },
        };
      case 'updateAllPages':
        return {
          ...state,
          pagesList: action.data,
        };
      case 'randomAvatar':
        return {
          ...state,
          randomAvatar: action.randomAvatar,
          currentEdit: {
            ...state.currentEdit,
            image: state.currentAvatarIsPicked
              ? state.currentEdit.image
              : action.randomAvatar,
          },
        };
      case 'pickedAvatar':
        return {
          ...state,
          pickedAvatar: {
            image: action.pickedAvatar.image,
          },
          currentEdit: {
            ...state.currentEdit,
            image: state.currentAvatarIsPicked
              ? action.pickedAvatar.image
              : state.currentEdit.image,
          },
        };
      case 'currentAvatarIsPicked':
        const { currentAvatarIsPicked } = action;
        return {
          ...state,
          currentAvatarIsPicked: action.currentAvatarIsPicked,
          currentEdit: {
            ...state.currentEdit,
            image: currentAvatarIsPicked
              ? state.pickedAvatar.image
              : state.randomAvatar,
          },
        };
      case 'currentEdit':
        return {
          ...state,
          currentEdit: {
            ...state.currentEdit,
            ...action.currentEdit,
          },
        };
      case 'profilePageLoad':
        return {
          ...state,
          defaultDisplayName: action.currentEdit.displayName,
          defaultUserName: action.currentEdit.userName,
          links: action.links,
          pageId: action.pageId,
          appointmentsEnabled: action.appointmentsEnabled,
          pickedAvatar: {
            image: action.currentEdit.image,
          },
          socialLinks:
            action.socialLinks.length > 0
              ? action.socialLinks
              : initialState.socialLinks,
          forwardLink: action.forwardLink
            ? action.forwardLink
            : initialState.forwardLink,
          currentEdit: {
            ...state.currentEdit,
            ...action.currentEdit,
          },
        };
      case 'linkPush':
        return {
          ...state,
          links: [...state.links, action.linkPush],
        };
      case 'linkMove':
        arr.splice(
          action.linkMove.to,
          0,
          arr.splice(action.linkMove.from, 1)[0]
        );
        return {
          ...state,
          links: arr,
        };
      case 'linkUpdateAt':
        arr[action.linkUpdateAt.index] = {
          ...arr[action.linkUpdateAt.index],
          ...action.linkUpdateAt.item,
        };
        return {
          ...state,
          links: [...arr],
        };
      case 'linkRemoveAt':
        arr.splice(action.linkRemoveAt, 1);
        return {
          ...state,
          links: [...arr],
        };
      case 'socialLinkPush':
        return {
          ...state,
          socialLinks: [...state.socialLinks, action.socialLinkPush],
        };
      case 'socialLinkMove':
        socialLinkArr.splice(
          action.socialLinkMove.to,
          0,
          socialLinkArr.splice(action.socialLinkMove.from, 1)[0]
        );
        return {
          ...state,
          socialLinks: socialLinkArr,
        };
      case 'socialLinkUpdateAt':
        socialLinkArr[action.socialLinkUpdateAt.index] = {
          ...socialLinkArr[action.socialLinkUpdateAt.index],
          ...action.socialLinkUpdateAt.item,
        };
        return {
          ...state,
          socialLinks: [...socialLinkArr],
        };
      case 'socialLinkRemoveAt':
        socialLinkArr.splice(action.socialLinkRemoveAt, 1);
        return {
          ...state,
          socialLinks: [...socialLinkArr],
        };
      case 'submitPublishData':
        const data = {
          pageName: state.currentEdit.userName,
          displayName: state.currentEdit.displayName ?? '',
          image: state.currentEdit.image ?? '',
          currentAvatarIsPicked: state.currentAvatarIsPicked,
          userName: state.currentEdit.userName,
          links: state.links,
          socialLinks: state.socialLinks,
          affiliateEnabled: state.affiliateEnabled,
          forwardLink: state.forwardLink,
          appointmentsEnabled: state.appointmentsEnabled,
        };
        action.ref
          .update(data)
          .then(res => {
            action.addToast('Changes successfully saved', {
              appearance: 'success',
              autoDismiss: true,
            });
          })
          .catch(err => {
            action.addToast('There was an error, please try again', {
              appearance: 'error',
              autoDismiss: true,
            });
            console.log(err);
          });
        return state;

      case 'questionPush':
        return questionPush(action, state);
      case 'questionMove':
        return questionMove(action, state);
      case 'questionUpdateAt':
        return questionUpdateAt(action, state);
      case 'submitQuestionData':
        return submitQuestionData(action, state);
      case 'managePageload':
        return managePageload(action, state);
      case 'questionRemoveAt':
        return questionRemoveAt(action, state);
      case 'createNewPage':
        action.ref
          .add({
            ...action.data,
          })
          .then(res => console.log(res))
          .catch(err => {
            console.log(err);
            action.addToast('There was an error, please try again', {
              appearance: 'error',
              autoDismiss: true,
            });
          });
        return state;

      default:
        return state;
    }
  },
  {
    ...initialState,
  }
);
