import styled from 'styled-components';
import { props as systemProps } from '@styled-system/should-forward-prop';

import {
  compose,
  space,
  color,
  layout,
  typography,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow,
  width,
  minWidth,
  height,
  minHeight,
} from 'styled-system';
import css from '@styled-system/css';

export const base = props => css(props.__css)(props.theme);

const styledSystem = tag => {
  return styled(tag).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return defaultValidatorFn(prop) && !systemProps.includes(prop);
    },
  })(
    base,
    compose(
      space,
      color,
      layout,
      typography,
      flexbox,
      border,
      background,
      position,
      grid,
      shadow,
      width,
      minWidth,
      height,
      minHeight
    )
  );
};

export default styledSystem;
