import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  AuthCheck,
  SuspenseWithPerf,
  preloadAuth,
  preloadFirestore,
  preloadFirestoreDoc,
  preloadRemoteConfig,
  preloadStorage,
  preloadUser,
  useFirebaseApp,
  preloadAnalytics,
  preloadFunctions,
} from 'reactfire';

import { ToastProvider } from 'react-toast-notifications';
import { Fallback, ConnectivityListener } from './components';
import { ThemeProvider } from './modules/theme';


const Login = lazy(() => import('./pages/login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Appointments = lazy(() => import('./pages/Appointments'));
const Settings = lazy(() => import('./pages/Settings'));
const Oauth = lazy(() => import('./pages/oauth'));
const PageViewLogger = lazy(() => import('./components/PageViewLogger'));

const FUNCTIONS_REGION = 'asia-south1';

const preloadSDKs = firebaseApp => {
  return Promise.all([
    preloadFirestore({
      firebaseApp,
      setup: async factory => {
        const firestore = factory();
       /* if (process.env.NODE_ENV !== 'production') {
          firestore.useEmulator('localhost', 8081)
        } */
        if (process.env.NODE_ENV === 'production') {
          await firestore.enablePersistence({ synchronizeTabs: true });
        }

        return firestore;
      },
    }),
    preloadFunctions({
      firebaseApp,
      setup: factory => {
        const functions = factory(FUNCTIONS_REGION);

        /* if (process.env.NODE_ENV !== 'production') {
          functions.useEmulator('localhost', 5002)
        } */

        return functions;
      },
    }),
    preloadStorage({
      firebaseApp,
      setup(storage) {
        return storage().setMaxUploadRetryTime(10000);
      },
    }),
    preloadAuth({
      firebaseApp,
      setup: async factory => {
        const auth = factory();

        /* if (process.env.NODE_ENV !== 'production') {
          auth.useEmulator('http://localhost:9099')
        } */

        return auth;
      },
    }),
    preloadRemoteConfig({
      firebaseApp,
      setup(remoteConfig) {
        remoteConfig().settings = {
          minimumFetchIntervalMillis: 10000,
          fetchTimeoutMillis: 10000,
        };
        return remoteConfig().fetchAndActivate();
      },
    }),
    preloadAnalytics({ firebaseApp }),
  ]);
};

const preloadData = async firebaseApp => {
  const user = await preloadUser({ firebaseApp });

  if (user) {
    await preloadFirestoreDoc(
      firestore => firestore.doc('count/counter'),
      firebaseApp
    );
  }
};

function App() {
  const firebaseApp = useFirebaseApp();
  preloadSDKs(firebaseApp).then(() => preloadData(firebaseApp));

  return (
    <ThemeProvider>
      <ToastProvider>
        <ConnectivityListener />
        <Router>
          <Switch>
            <Route exact path="/">
              <SuspenseWithPerf
                traceId={'firebase-user-wait-dashboard'}
                fallback={<Fallback />}
              >
                <AuthCheck fallback={<Redirect to="/login" />}>
                  <Dashboard />
                </AuthCheck>
              </SuspenseWithPerf>
            </Route>
            <Route path="/profile">
              <SuspenseWithPerf
                traceId={'firebase-user-wait-profile'}
                fallback={<Fallback />}
              >
                <AuthCheck fallback={<Redirect to="/login" />}>
                  <Profile />
                </AuthCheck>
              </SuspenseWithPerf>
            </Route>
            <Route path="/appointments">
              <SuspenseWithPerf
                traceId={'firebase-user-wait-appointments'}
                fallback={<Fallback />}
              >
                <AuthCheck fallback={<Redirect to="/login" />}>
                  <Appointments />
                </AuthCheck>
              </SuspenseWithPerf>
            </Route>
            <Route path="/settings">
              <SuspenseWithPerf
                traceId={'firebase-user-wait-appointments'}
                fallback={<Fallback />}
              >
                <AuthCheck fallback={<Redirect to="/login" />}>
                  <Settings />
                </AuthCheck>
              </SuspenseWithPerf>
            </Route>
            <Route exact path="/login">
              <SuspenseWithPerf
                traceId={'firebase-user-login'}
                fallback={<Fallback />}
              >
                <Login />
              </SuspenseWithPerf>
            </Route>
            <Route exact path="/auth/spotify">
              <SuspenseWithPerf
                traceId={'firebase-user-auth-external'}
                fallback={<Fallback />}
              >
                <Oauth loginType="spotify" />
              </SuspenseWithPerf>
            </Route>
            <Route exact path="/auth/linkedin">
              <SuspenseWithPerf
                traceId={'firebase-user-auth-external'}
                fallback={<Fallback />}
              >
                <Oauth loginType="linkedin" />
              </SuspenseWithPerf>
            </Route>
            <Route exact path="*">
              <SuspenseWithPerf
                traceId={'firebase-user-page-not-found'}
                fallback={<Fallback />}
              >
                <h1>page not found</h1>
              </SuspenseWithPerf>
            </Route>
          </Switch>
          <SuspenseWithPerf
            traceId={'firebase-user-pageview'}
            fallback={<Fallback />}
          >
            <PageViewLogger />
          </SuspenseWithPerf>
        </Router>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
