import React, { memo, useCallback, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useDebounce } from 'react-use';
import { useToasts } from 'react-toast-notifications';

import { MaskedInput, Text, Button } from './index';
import { dispatch, initialState } from '../states';

function UsernameInput({
  userName,
  placeholder = 'Add username',
  fontSize = ['body', 'paragraph', 'subheader'],
  prefix,
  updateUserProfile,
  onDebouncedValue,
  currentUserName = '',
  withConfirm,
  authorId,
  onCloseModal,
  ...rest
}) {
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState(currentUserName);
  const [available, setAvailable] = useState('');
  const firestore = useFirestore();
  const publishedPagesRef = firestore.collection('publishedPages');
  const { addToast } = useToasts();
  const [debouncedValue, setDebouncedValue] = useState('');
  const pagesRef = firestore.collection('pages');
  const createdAt = useFirestore.Timestamp.fromDate(new Date());

  const [,] = useDebounce(
    async () => {
      setLoading(true);
      if (val) {
        const pageName = publishedPagesRef.doc(val);
        const docSnapshot = await pageName.get();
        const isExists = docSnapshot.exists;
        if (isExists) {
          setLoading(false);
          setAvailable(false);
        } else {
          setDebouncedValue(val);
          setLoading(false);
          setAvailable(true);
        }
      }
    },
    1000,
    [val]
  );

  function onConfirm() {
    dispatch({
      ref: pagesRef,
      addToast,
      data: {
        pageName: debouncedValue,
        authorId: authorId,
        userName: debouncedValue,
        createdAt,
        roles: {
          [authorId]: 'owner',
        },
        questions: initialState.questions,
        appointmentsEnabled: true,
      },
      type: 'createNewPage',
    });
    if (typeof onCloseModal === 'function') {
      onCloseModal();
    }
  }

  function isUserName() {
    let regEmail = /^(?=.{3,30}$)([a-z0-9_][a-z0-9_]*([.][a-z0-9_]+)*)$/;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  function checkError() {
    if (currentUserName != null && currentUserName === val) {
      return false;
    } else if (!isUserName()) {
      return true;
    } else {
      return !loading && !available && val;
    }
  }

  function checkSuccess() {
    if (currentUserName != null && currentUserName === val) {
      return true;
    } else {
      return !loading && available && val && isUserName();
    }
  }

  const onChange = useCallback(
    e => {
      const { name, value } = e.currentTarget;
      setLoading(false);
      setVal(value);
      if (updateUserProfile) {
        dispatch({
          currentEdit: {
            [name]: value,
          },
          type: 'currentEdit',
        });
      }
    },
    [updateUserProfile]
  );

  const error = checkError();
  const success = checkSuccess();

  return (
    <>
      <MaskedInput
        name="userName"
        defaultValue={userName}
        placeholder="coolname"
        fontSize={['caption', 'paragraph', 'title']}
        onChange={onChange}
        type="text"
        prefix={prefix}
        error={val && error}
        success={val && success}
        loading={loading && val}
        maxLength={30}
        value={val?.toLowerCase()}
        {...rest}
      />
      {val && error && (
        <Text color="danger" fontSize="caption" marginTop="medium" textAlign="center">
          {!isUserName()
            ? 'A username is limited to 30 characters and must contain only letters, numbers, periods, and underscores'
            : 'Taken username'}
        </Text>
      )}
      {withConfirm && (
        <Button
          mt="large"
          width="150px"
          onClick={onConfirm}
          disabled={loading || !debouncedValue || error}
          error={error}
        >
          Confirm
        </Button>
      )}
    </>
  );
}

export default memo(UsernameInput);
