import React, { forwardRef, memo } from 'react';
import { motion, useCycle } from 'framer-motion';

import { Box, Flex, AvatarImagePicker, BigHeadsAvatar } from './index';
import { ReactComponent as BothWay } from '../assets/svgs/both-way.svg';

import { dispatch } from '../states';

const spring = {
  type: 'spring',
  stiffness: 950,
  damping: 50,
};
const Avatar = forwardRef(
  (
    {
      children,
      block,
      accept = '.gif, .ico, .png, .jpg, .jpeg, .svg',
      src,
      withOverly,
      isSmall,
      deletable,
      defaultValue,
      control,
      name,
      disabled,
      randomable,
      pageId,
      ...props
    },
    ref
  ) => {
    const itemsA = [
      { key: 1, component: <AvatarImagePicker  withOverly pageId={pageId} /> },
      { key: 2, component: <BigHeadsAvatar isSmall disabled /> },
    ];
    const itemsB = [
      { key: 2, component: <BigHeadsAvatar disabled /> },
      { key: 1, component: <AvatarImagePicker withOverly isSmall pageId={pageId} /> },
    ];
    const [items, setItems] = useCycle(itemsA, itemsB);

    function swipeThem() {
      dispatch({
        currentAvatarIsPicked: items[0].key !== 1,
        type: 'currentAvatarIsPicked',
      });
      setItems();
    }

    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          width="210px"
          position="absolute"
          // left={`calc(50% - ${items[0].key === 1 ? 50 : 30}px)`}
          left={`calc(50% - 50px)`}
          {...props}
        >
          {items.map(item => (
            <motion.div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              key={item.key}
              layoutId={item.key}
              transition={spring}
            >
              {item.component}
            </motion.div>
          ))}
        </Flex>
        <Box
          as="button"
          onClick={swipeThem}
          width="20px"
          height="20px"
          position="absolute"
          left="calc(50% + 65px)"
        >
          <BothWay />
        </Box>
      </>
    );
  }
);

export default memo(Avatar);
