import React, { memo } from 'react';

import { Box, Button, Flex, Heading, Link, Icon, Logo, Text } from './index';
import { useGlobalState } from '../states';
import { ReactComponent as Profile } from '../assets/svgs/profile.svg';

function renderImage({ image, currentAvatarIsPicked, isMobile }) {
  if (image) {
    if (currentAvatarIsPicked) {
      return (
        <Box
          as="img"
          src={image}
          borderRadius="9999px"
          height={isMobile ? '80px' : '150px'}
          width={isMobile ? '80px' : '150px'}
          margin={isMobile ? '30px 0' : '45px 0'}
          __css={{
            objectFit: 'cover',
          }}
        />
      );
    } else {
      return (
        <Box
          as="img"
          src={image}
          borderRadius="9999px"
          height={isMobile ? '80px' : '150px'}
          width={isMobile ? '80px' : '150px'}
          margin={isMobile ? '30px 0' : '45px 0'}
          __css={{
            objectFit: 'cover',
          }}
        />
      );
    }
  } else {
    return (
      <Box
        borderRadius="9999px"
        height={isMobile ? '80px' : '150px'}
        width={isMobile ? '80px' : '150px'}
        margin={isMobile ? '30px 0' : '45px 0'}
        __css={{
          objectFit: 'cover',
        }}
      >
        <Profile
          height={isMobile ? '80px' : '150px'}
          width={isMobile ? '80px' : '150px'}
        />
      </Box>
    );
  }
}

const UserProfile = props => {
  const { isMobile } = props;
  const [currentEdit] = useGlobalState('currentEdit');
  const [appointmentsEnabled] = useGlobalState('appointmentsEnabled');
  const [links = []] = useGlobalState('links');
  const [currentAvatarIsPicked] = useGlobalState('currentAvatarIsPicked');
  const [socialLinks] = useGlobalState('socialLinks');
  const { displayName, userName, image } = currentEdit;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="1rem"
      flex={1}
    >
      <Flex flexDirection="column" alignItems="center" width="100%">
        {renderImage({
          currentAvatarIsPicked,
          image,
          isMobile,
        })}
        <Heading
          as="h1"
          marginBottom="0.2rem"
          textAlign="center"
          fontSize={isMobile ? '2rem' : '3rem'}
          fontWeight="500"
          lineHeight={1}
        >
          {displayName}
        </Heading>
        <Heading
          as="h3"
          marginBottom="1rem"
          textAlign="center"
          fontSize={isMobile ? '1rem' : '2rem'}
          fontWeight="500"
          color="blue"
        >
          <Text color="primary.3" as="span">
            @
          </Text>
          {userName}
        </Heading>
        <Flex marginBottom="2rem">
          {socialLinks.map(item => {
            const { id, icon, url, color } = item;
            return (
              icon && (
                <Link
                  as="a"
                  href={url}
                  target="_blank"
                  marginRight="1rem"
                  key={id}
                >
                  <Icon
                    icon={icon}
                    color={color}
                    fontSize={isMobile ? '1.5rem' : '2.5rem'}
                  />
                </Link>
              )
            );
          })}
        </Flex>
        {appointmentsEnabled && (
          <Flex marginBottom="xlarge">
            <Button
              variant="default"
              fontWeight={500}
              py={isMobile ? 'xsmall' : 'medium'}
              fontFamily={isMobile ? 'paragraph' : 'title'}
            >
              Book a call
            </Button>
          </Flex>
        )}
        <Flex flexDirection="column" alignItems="center" width="100%">
          {links.map((item, index) => {
            return (
              item.show && (
                <Flex
                  key={`${item.url}-${index}`}
                  padding="1rem"
                  marginBottom="2rem"
                  background="rgba(248, 126, 15, 0.1)"
                  borderRadius="10px"
                  alignItems="center"
                  justifyContent="space-between"
                  maxWidth="800px"
                  width="90%"
                >
                  {item.image && (
                    <Box
                      pr={isMobile ? 'xxsmall' : 'xsmall'}
                      minWidth={isMobile ? '40px' : '60px'}
                      maxWidth={isMobile ? '40px' : '60px'}
                    >
                      <Box
                        as="img"
                        src={item.image}
                        alt={`${item.title} link`}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  )}
                  <Heading
                    fontSize={isMobile ? '1rem' : '2rem'}
                    pr={isMobile ? 'xxsmall' : 'xsmall'}
                  >
                    {item.title}
                  </Heading>
                  <Button
                    as="a"
                    href={item.url}
                    variant={item.buttonType || 'default'}
                    fontWeight={500}
                    py={isMobile ? 'xsmall' : 'medium'}
                    fontFamily={isMobile ? 'paragraph' : 'title'}
                  >
                    {item.buttonText || 'Click'}
                  </Button>
                </Flex>
              )
            );
          })}
        </Flex>
      </Flex>
      <Logo />
    </Flex>
  );
};

export default memo(UserProfile);
