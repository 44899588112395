import styled, { keyframes } from 'styled-components';
import styledSystem from '../utils/styledSystem';
import { motion, AnimatePresence } from 'framer-motion';

const spin = keyframes`
  0% {
      transform: rotate(0);
      stroke-dashoffset: 0;
    }
  50% {
    stroke-dashoffset: 1;
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
    stroke-dashoffset: 0;
  }
`;

const LoaderWrapper = styledSystem(styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .spinner__path {
    animation: ${spin} 3s ease infinite;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
`);

const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

function Loader({ isLoading, size = 24, strokeWidth = 4, ...props }) {
  return (
    <LoaderWrapper {...props}>
      <AnimatePresence>
        {isLoading && (
          <motion.svg
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            className="spinner"
          >
            <circle
              cx={size / 2}
              cy={size / 2}
              r={size / 2 - 2}
              stroke="currentColor"
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              className="spinner__path"
              pathLength="1"
            />
          </motion.svg>
        )}
      </AnimatePresence>
    </LoaderWrapper>
  );
}

export default Loader;
