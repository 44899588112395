import styled from 'styled-components';
import styledSystem from '../utils/styledSystem';

const Container = styledSystem(styled.main`
  box-sizing: border-box;
  width: 100%;
`);

Container.defaultProps = {
  maxWidth: '84rem',
  mx: 'auto',
  px: '2rem',
};

export default Container;
