import colors from './colors';

const borders = {
  primary: `1px solid ${colors.border}`,
  success: `1px solid ${colors.success}`,
  danger: `1px solid ${colors.danger}`,
  info: `1px solid ${colors.info}`,
  warning: `1px solid ${colors.warning}`,
  black: `1px solid ${colors.black}`,
  gray: `1px solid ${colors.gray}`,
};

export const radius = {
  none: '0px',
  xsmall: '2px',
  small: '4px',
  medium: '6px',
  large: '8px',
  xlarge: '12px',
  rounded: '1000rem',
};

export const borderWidths = {
  none: 'Opx',
  xsmall: '1px',
  small: '2px',
  medium: '4px',
  large: '8px',
};

export default borders;
