import React, { useCallback, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Box, Flex } from './index';
import { ReactComponent as Cross } from '../assets/svgs/cross.svg';
import { ReactComponent as Tick } from '../assets/svgs/tick.svg';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function dateFormat(date) {
  return dayjs(date).format('DD-MMM-YYYY');
}

function timeFormat(props) {
  const {
    row: { values },
  } = props;
  const { date, time } = values;
  const appointmentDate = dayjs(date).format('MM-DD-YYYY');
  const currentDate = dayjs(`${appointmentDate} ${time} UTC`);
  const localTime = currentDate.local().format('h:mm A');
  return localTime;
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <Box
        as="table"
        {...getTableProps()}
        borderBottom="1px solid"
        borderColor="info.0"
        textAlign="left"
        width="100%"
        __css={{
          tr: {
            ':last-child': {
              td: {
                borderBottom: 0,
              },
            },
          },
          th: {
            color: 'text'
          },
          'th, td': {
            margin: 0,
            padding: '0.5rem',
            borderBottom: '1px solid',
            borderRight: '0',
            borderColor: 'info.0',

            ':last-child': {
              borderRight: 0,
            },
          },
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.id !== 'actions'
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Box>
    </>
  );
}

function TableWrapper({ data, onApprove, onReject, showApproveButton, showRejectButton }) {
  const approveClick = useCallback((cell) => {
    if (typeof onApprove === 'function') {
      onApprove(cell);
    }
  }, [onApprove]);

  const rejectClick = useCallback((cell) => {
    if (typeof onReject === 'function') {
      onReject(cell);
    }
  }, [onReject]);

  const columns = useMemo(
    () => [
      {
        Header: 'Page Name',
        accessor: 'pageName',
      },
      {
        Header: 'Seeker Name',
        accessor: 'seeker',
      },
      {
        Header: 'Seeker Email',
        accessor: 'seekerEmail',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: props => dateFormat(props.value),
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: props => timeFormat(props),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ cell }) => (
          <Flex>
            {showApproveButton && <Flex as="button" alignItems="center" color='success' onClick={e => approveClick(cell)}>
              Approve
              <Box
                as={Tick}
                width="15px"
                height="15px"
                marginLeft="xsmall"
                __css={{ fill: 'success' }}
              />
            </Flex>}
            {showRejectButton && <Flex as="button" alignItems="center" color='danger' onClick={e => rejectClick(cell)} marginLeft="small">
              Cancel
              <Box
                as={Cross}
                width="15px"
                height="15px"
                marginLeft="xsmall"
                __css={{ fill: 'danger' }}
              />
            </Flex>}
          </Flex>
        ),
      },
    ],
    [approveClick, rejectClick, showApproveButton, showRejectButton]
  );

  return <Table columns={columns} data={data} />;
}

export default TableWrapper;
