import React, { memo, useCallback, useEffect } from 'react';

import { Box, Flex, Dropdown, Heading, Logo, IconButton } from '../components';
import { dispatch } from '../states';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import { ReactComponent as Notification } from '../assets/svgs/notification.svg';

function getListOptions(pages, pathName) {
  const reduced = Object.keys(pages).reduce(
    (acc, key) => {
      const item = pages[key];
      const { pageName, pageId } = item;
      if (pathName.includes(item.pageId)) {
        acc.defaultValue = {
          label: pageName,
          value: pageId,
        };
      }
      acc.listOptions.push({
        label: pageName,
        value: pageId,
      });
      return acc;
    },
    {
      defaultValue: {},
      listOptions: [],
    }
  );
  return reduced;
}

function Header({ name, noSwitch, uid }) {
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const firestore = useFirestore();
  const pagesRef = firestore.collection('users').doc(uid);
  const { data } = useFirestoreDocData(pagesRef);
  const {
    location: { pathname },
  } = history;
  const { pages = {} } = data || {};
  const pagesList = Object.values(pages);
  const { path } = match;
  const { defaultValue, listOptions } = getListOptions(pages, pathname);
  const { currentView } = params;

  useEffect(() => {
    dispatch({
      data: pagesList,
      type: 'updateAllPages',
    });
  }, [pagesList]);

  const onChange = useCallback(
    options => {
      const route = currentView
        ? path
            .replace(':id', options.value)
            .replace(':currentView', currentView)
        : path.replace(':id', options.value);
      history.push({
        pathname: route,
      });
    },
    [currentView, path, history]
  );

  return (
    <Flex flexDirection={['column-reverse', 'row', 'row']}>
      <Flex
        flex="1"
        justifyContent="space-between"
        alignItems={['flex-start', 'center', 'center']}
        flexDirection={['column', 'row', 'row']}
      >
        <Heading as="h1" fontSize={['h2', 'h1']} fontWeight="500">
          {name}
        </Heading>
        {!noSwitch && listOptions.length > 0 && (
          <Box mr="large">
            <Dropdown
              options={listOptions}
              defaultValue={defaultValue}
              onChange={onChange}
            />
          </Box>
        )}
      </Flex>
      <Flex
        mt={['large', 0]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Logo fontSize="h3" display={['block', 'none', 'none']} />
        <IconButton>
          <Notification
            width="30px"
            height="30px"
            viewBox="0 0 30 30"
            preserveAspectRatio="none"
          />
        </IconButton>
      </Flex>
    </Flex>
  );
}

export default memo(Header);
