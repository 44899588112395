import React, { useState, useRef, useCallback, memo } from 'react';
import { Input, Box, Text } from './index';

function InputFeild({ onChange, onBlur, errorText, ...rest }) {
  const [isInvalid, setInvalidUrl] = useState(false);
  const urlInputEl = useRef(null);

  const onUrlInputBlur = useCallback(
    e => {
      if (urlInputEl.current.validity.valid) {
        setInvalidUrl(false);
      } else {
        setInvalidUrl(true);
      }
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const errorStyle = isInvalid
    ? {
        border: '1px solid',
        borderColor: 'danger',
      }
    : {};

  return (
    <Box>
      <Input
        onChange={onChange}
        onBlur={onUrlInputBlur}
        ref={urlInputEl}
        {...rest}
        {...errorStyle}
      />
      {isInvalid && (
        <Text color="danger" fontSize="caption">
          {errorText}
        </Text>
      )}
    </Box>
  );
}

export default memo(InputFeild);
