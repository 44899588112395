import React, { memo, useCallback } from 'react';

import {
  Flex,
  IconButton,
  Switch,
  Input,
  Box,
  Dropdown,
} from './index';
import { ReactComponent as Grab } from '../assets/svgs/drag-indicator.svg';
import { ReactComponent as Trash } from '../assets/svgs/trash.svg';

import { dispatch } from '../states';

const allOptions = [
  {
    label: 'Short answer',
    value: 'text',
  },
  {
    label: 'Paragraph',
    value: 'textarea',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'tel',
  },
];

function getListOptions(options, selected) {
  const reduced = options.reduce(
    (acc, item) => {
      const { value } = item;
      if (value === selected) {
        acc.defaultValue = item;
      }
      acc.listOptions.push(item);
      return acc;
    },
    {
      defaultValue: {},
      listOptions: [],
    }
  );
  return reduced;
}

const QuestionCard = props => {
  const { link = {}, index, forwardLink, ...rest } = props;
  const { defaultValue, listOptions } = getListOptions(allOptions, link.type);

  const remove = useCallback(() => {
    dispatch({
      questionRemoveAt: index,
      type: 'questionRemoveAt',
    });
  }, [index]);

  const update = useCallback(
    (name, value) => {
      dispatch({
        questionUpdateAt: {
          index,
          item: {
            [name]: value,
          },
        },
        type: 'questionUpdateAt',
      });
    },
    [index]
  );

  return (
    <Flex
      bg="white"
      boxShadow="0px 7px 15px -4px rgba(13, 15, 19, 0.1)"
      borderRadius="10px"
      width="100%"
      px={'xsmall'}
      py={'small'}
      justifyContent="space-between"
      flexDirection="column"
      {...rest}
    >
      <Flex height="100%" alignItems="center">
        <Box mr={'small'} as={Grab}></Box>
        <Flex flexDirection="column" flex="1 1 100%">
          <Box
            pb="large"
            mb="small"
            borderBottom="1px solid"
            borderColor="primary.1"
          >
            <Flex pb="xxsmall" pr="small">
              <Input
                width="100%"
                placeholder="Label here"
                fontWeight="600"
                fontSize={['body', 'paragraph', 'subheader']}
                color="text"
                name={`links[${index}].title`}
                defaultValue={link.title}
                onChange={e => update('title', e.currentTarget.value)}
              />
            </Flex>
            <Box pb="xxsmall" pr="small">
              <Dropdown
                options={listOptions}
                defaultValue={defaultValue}
                onChange={e => update('type', e.value)}
                size="small"
              />
            </Box>
          </Box>
          <Flex alignItems="center" justifyContent="flex-end" mr="small">
            <Switch
              name={`links[${index}].show`}
              onChange={e => update('show', e.target.checked)}
              defaultChecked={link.show}
            >
              Show
            </Switch>
            <IconButton onClick={remove} mx="small">
              <Trash />
            </IconButton>
            <Box borderLeft="1px solid" borderColor="primary.1" pl="small">
              <Switch
                name={`links[${index}].required`}
                onChange={e => update('required', e.target.checked)}
                defaultChecked={link.required}
              >
                Required
              </Switch>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(QuestionCard);
