import React from 'react';

import { Tab, Flex, Text } from './index';

export default function Tabset({ data = [] }) {
  return (
    <Flex
      borderBottom="1px solid"
      borderColor="primary.1"
      pl="small"
      as="ul"
      overflowX="scroll"
    >
      {data.map(({ label, to }) => (
        <Tab to={to} key={to}>
          <Text>{label}</Text>
        </Tab>
      ))}
    </Flex>
  );
}
