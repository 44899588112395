import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { dispatch, useGlobalState } from '../states';
import guidGenerator from '../utils/guidGenerator';

import { EmptySocialCard, SocialCard, IconButton, Flex, Box } from './index';

import { ReactComponent as Add } from '../assets/svgs/add.svg';

const CardItem = styled.div`
  width: 100%;
`;

function SocialCardLink({ link, index, forwardLink }) {
  return (
    <Draggable draggableId={link.id} index={index}>
      {provided => (
        <CardItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {link.icon ? (
            <SocialCard link={link} index={index} forwardLink={forwardLink} />
          ) : (
            <EmptySocialCard
              link={link}
              index={index}
              forwardLink={forwardLink}
            />
          )}
        </CardItem>
      )}
    </Draggable>
  );
}

const SocialCardLinkList = React.memo(function SocialCardLinkList({
  socialLinks,
}) {
  return socialLinks.map((link, index) => (
    <SocialCardLink link={link} index={index} key={link.id} />
  ));
});

function DraggableSocialButtons() {
  const [socialLinks] = useGlobalState('socialLinks');
  const onDragEnd = useCallback(result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    dispatch({
      socialLinkMove: {
        from: result.source.index,
        to: result.destination.index,
      },
      type: 'socialLinkMove',
    });
  }, []);

  const onAddLink = useCallback(() => {
    dispatch({
      socialLinkPush: {
        icon: null,
        url: '',
        id: guidGenerator(),
      },
      type: 'socialLinkPush',
    });
  }, []);

  return (
    <Box
      bg="white"
      boxShadow="0px 7px 15px -4px rgba(13, 15, 19, 0.1)"
      borderRadius="10px"
      mb="large"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <SocialCardLinkList socialLinks={socialLinks} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {socialLinks.length < 6 && (
        <Flex flexDirection="column" alignItems="center">
          <IconButton
            width="50px"
            height="50px"
            mb="medium"
            mt="medium"
            onClick={onAddLink}
            py="small"
            border="1px dashed black"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="9999px"
          >
            <Add width="25px" height="25px" />
          </IconButton>
        </Flex>
      )}
    </Box>
  );
}

export default memo(DraggableSocialButtons);
