import React, { forwardRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import styledSystem from '../utils/styledSystem';

import { variant } from 'styled-system';
import { motion, AnimatePresence } from 'framer-motion';

const spin = keyframes`
  0% {
      transform: rotate(0);
      stroke-dashoffset: 0;
    }
  50% {
    stroke-dashoffset: 1;
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
    stroke-dashoffset: 0;
  }
`;

const ButtonWrapper = styledSystem(
  styled.button(
    variant({
      variants: {
        default: {
          bg: 'transparent',
          color: 'primary.3',
          borderColor: 'primary.3',
          borderStyle: 'solid',
          borderWidth: '2px',
          '&:hover': {
            borderColor: 'primary.4',
            color: 'primary.4',
          },
        },
        primary: {
          color: 'white',
          bg: 'primary.3',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: 'transparent',
          '&:hover': {
            bg: 'primary.4',
          },
        },
        tertiary: {
          color: 'primary.3',
          border: 'none',
        },
      },
    }),
    ({ theme, textColor, color: bgColor, disabled }) => css`
      position: relative;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      transition: 200ms;
      white-space: nowrap;
      font-weight: 500;

      .spinner {
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .spinner__path {
        animation: ${spin} 3s ease infinite;
        stroke-dasharray: 1;
        stroke-dashoffset: 0;
        animation-fill-mode: forwards;
        transform-origin: 50% 50%;
      }
    `
  )
);

ButtonWrapper.defaultProps = {
  py: 'xsmall',
  px: '2rem',
  borderRadius: '0.25rem',
  fontSize: 'body',
  fontFamily: 'body',
  fontWeight: 'black',
  variant: 'default',
};

const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

function Button({ children, loading, disabled, ...props }, ref) {
  const isLoading = loading && !disabled;
  return (
    <ButtonWrapper {...{ ...props, disabled, ref }}>
      <AnimatePresence>
        {isLoading && (
          <motion.svg
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            viewBox="0 0 24 24"
            fill="none"
            className="spinner"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              className="spinner__path"
              pathLength="1"
            />
          </motion.svg>
        )}
      </AnimatePresence>
      <motion.span
        variants={variants}
        animate={isLoading ? 'initial' : 'enter'}
      >
        {children}
      </motion.span>
    </ButtonWrapper>
  );
}

export default forwardRef(Button);
