import styled from 'styled-components';
import styledSystem from '../utils/styledSystem';

const Heading = styledSystem(styled.h2``);

Heading.defaultProps = {
  fontSize: "h2",
  color: 'text'
};

export default Heading;
