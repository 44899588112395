import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { dispatch, useGlobalState } from '../states';
import guidGenerator from '../utils/guidGenerator';

import { Card, Button, Flex } from './index';

const CardItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

function CardLink({ link, index, forwardLink }) {
  return (
    <Draggable draggableId={link.id} index={index}>
      {provided => (
        <CardItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card link={link} index={index} forwardLink={forwardLink} />
        </CardItem>
      )}
    </Draggable>
  );
}

const CardLinkList = React.memo(function CardLinkList() {
  const [links] = useGlobalState('links');
  const [forwardLink] = useGlobalState('forwardLink');
  return links.map((link, index) => (
    <CardLink
      link={link}
      index={index}
      key={link.id}
      forwardLink={forwardLink}
    />
  ));
});

function DraggableCards() {
  const onDragEnd = useCallback(result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    dispatch({
      linkMove: {
        from: result.source.index,
        to: result.destination.index,
      },
      type: 'linkMove',
    });
  }, []);

  const onAddLink = useCallback(() => {
    dispatch({
      linkPush: {
        title: 'Add your title?',
        show: true,
        url: '',
        id: guidGenerator(),
      },
      type: 'linkPush',
    });
  }, []);

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Button
          width="300px"
          mb="medium"
          mt="medium"
          onClick={onAddLink}
          py="small"
          data-tut="coolbio__addLinkButtons"
        >
          Add a link
        </Button>
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <CardLinkList />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default memo(DraggableCards);
