import React from 'react';
import { Chart, LineAdvance } from 'bizcharts';

export default function LineAdvanceChart({ data }) {
  return (
    <Chart padding={[10, 20, 50, 40]} autoFit height={300} data={data}>
      <LineAdvance
        shape="smooth"
        point
        area
        position="date*views"
      />
    </Chart>
  );
}
