export function questionPush(action, state) {
  return {
    ...state,
    questions: [...state.questions, action.questionPush],
  };
}

export function questionMove(action, state) {
  const {
    questionMove: { to, from },
  } = action;
  const arr = [...state.questions];
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return {
    ...state,
    questions: arr,
  };
}

export function questionUpdateAt(action, state) {
  const {
    questionUpdateAt: { index, item },
  } = action;
  const arr = [...state.questions];
  arr[index] = {
    ...arr[index],
    ...item,
  };
  return {
    ...state,
    questions: [...arr],
  };
}

export function managePageload(action, state) {
  const { questions } = action;
  return {
    ...state,
    questions: questions ? questions : state.questions,
  };
}

export function questionRemoveAt(action, state) {
  const { questionRemoveAt } = action;
  const arr = [...state.questions];
  arr.splice(questionRemoveAt, 1);
  return {
    ...state,
    questions: [...arr],
  };
}

export function submitQuestionData(action, state) {
  const { questions } = state;

  action.ref
    .update({
      questions,
    })
    .then(res => {
      action.addToast('Changes successfully saved', {
        appearance: 'success',
        autoDismiss: true,
      });
    })
    .catch(err => {
      action.addToast('There was an error, please try again', {
        appearance: 'error',
        autoDismiss: true,
      });
    });

  return {
    ...state,
  };
}
