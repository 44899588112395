import React from 'react';

import { Link, Flex, NavLink } from '.';

import { ReactComponent as Dash } from '../assets/svgs/dash.svg';
import { ReactComponent as Settings } from '../assets/svgs/settings.svg';
import { ReactComponent as Calendar } from '../assets/svgs/calendar.svg';

const MobileBar = () => {
  return (
    <Flex
      padding="small"
      bg="primary.2"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Link to="/">
        <Dash />
      </Link>
      <NavLink
        to="/appointments"
        isActive={(match, location) => {
          if (!match) {
            return false;
          }
          const { url } = match;
          return url.includes('/appointments');
        }}
      >
        <Calendar />
      </NavLink>
      <NavLink to="/settings" exact>
        <Settings />
      </NavLink>
    </Flex>
  );
};

export default MobileBar;
