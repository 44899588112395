import React from 'react';
import { useAuth } from 'reactfire';
import { useHistory } from 'react-router-dom';

import { Box, Flex, NavLink, Tooltip, IconButton } from '../components';

import { ReactComponent as Logo } from '../assets/svgs/slogo.svg';
import { ReactComponent as Analytics } from '../assets/svgs/analytics.svg';
import { ReactComponent as Dash } from '../assets/svgs/dash.svg';
import { ReactComponent as Settings } from '../assets/svgs/settings.svg';
import { ReactComponent as Logout } from '../assets/svgs/logout.svg';
import { ReactComponent as Calendar } from '../assets/svgs/calendar.svg';

const Sidebar = () => {
  const auth = useAuth();
  const history = useHistory();

  function logout(params) {
    auth.signOut();
    history.push('/login');
  }

  return (
    <Flex
      justifyContent="space-evenly"
      flexDirection="column"
      alignItems="center"
      margin="0"
      width="5em"
      display={['none', 'none', 'flex']}
      bg="primary.1"
      p={10}
      as="nav"
      height="100vh"
      position="relative"
    >
      <Logo width="60px" />
      <Flex
        flexDirection="column"
        height="31.25em"
        alignItems="center"
        justifyContent="space-around"
      >
        <Flex
          height="15.625em"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          as="ul"
        >
          <Box as="li" __css={{ listStyleType: 'none' }}>
            <Tooltip text="Dashboard">
              <NavLink to="/" exact>
                <Dash />
              </NavLink>
            </Tooltip>
          </Box>
          <Box as="li" __css={{ listStyleType: 'none' }}>
            <Tooltip text="Analytics coming soon">
              <NavLink to="/analytics" exact disable>
                <Analytics />
              </NavLink>
            </Tooltip>
          </Box>
          <Box as="li" __css={{ listStyleType: 'none' }}>
            <Tooltip text="Appointments">
              <NavLink
                to="/appointments"
                isActive={(match, location) => {
                  if (!match) {
                    return false;
                  }
                  const { url } = match;
                  return url.includes('/appointments');
                }}
              >
                <Calendar />
              </NavLink>
            </Tooltip>
          </Box>
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-evenly"
        height="12.5em"
        flexDirection="column"
        alignItems="center"
        as="ul"
      >
        <Box as="li" __css={{ listStyleType: 'none' }}>
          <Tooltip text="Settings">
            <NavLink to="/settings" exact>
              <Settings />
            </NavLink>
          </Tooltip>
        </Box>
        <Box as="li" __css={{ listStyleType: 'none' }}>
          <Tooltip text="Logout">
            <IconButton onClick={logout}>
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
