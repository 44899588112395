import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../../theme'
import reset from '../../utils/restStyle'

const GlobalStyle = createGlobalStyle`
  ${reset}
  html,
  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }

  #root {
    display: flex;
  }
`

export default function ThemeProviderWrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

ThemeProviderWrapper.propTypes = {
  children: PropTypes.element
}
