import React, { forwardRef } from 'react';
import { Box, Label, Text } from './index';

export default forwardRef(
  ({ children, block, name, disabled, ...rest }, ref) => {
    return (
      <Label
        __css={{
          position: 'relative',
          display: 'inline-flex',
          cursor: disabled ? 'auto' : 'pointer',
          '&:focus-within': {
            '@media (-webkit-min-device-pixel-ratio: 0)': {
              outlineColor: '-webkit-focus-ring-color',
              outlineStyle: 'auto',
            },
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineColor: 'Highlight',
          },
        }}
      >
        {children && <Text mr="small" fontSize="body">{children}</Text>}
        <Box
          as="input"
          type="checkbox"
          ref={ref}
          __css={{
            position: 'absolute',
            opacity: 0,
            '&:checked + div': {
              backgroundColor: 'primary.3',
              '&:before': {
                backgroundColor: 'white',
                border: '0.25rem solid primary.2',
                transform: 'translateX(2rem)',
              },
            },
          }}
          name={name}
          disabled={disabled}
          {...rest}
        />
        <Box
          position="relative"
          height="1.5rem"
          width="3rem"
          backgroundColor="primary.2"
          borderRadius="1rem"
          transition="200ms"
          flexShrink={0}
          __css={{
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: '50%',
              height: '1.5rem',
              width: '1.5rem',
              backgroundColor: 'white',
              border: '0.25rem solid primary.2',
              transition: '200ms',
            },
          }}
        />
      </Label>
    );
  }
);
